<template>
    <section class="comparison">
        <div class="container p-5">
            <h2 class="text-center font-weight-bold mt-2 mb-5"
                data-aos="fade"
                data-aos-duration="1000">
                ULTRA FEMME 360™<br>vs<br>傳統外科手術<br>vs<br>舊式Laser CO2激光收陰
            </h2>
            <div class="cards row justify-content-center pt-4 mb-4">
                <div class="col-lg card mb-4 mb-lg-0 mr-md-4 p-0"
                     data-aos="fade-up"
                     data-aos-duration="1000">
                    <div class="card-body">
                        <div class="card-title text-center featured d-flex flex-column justify-content-center px-3 py-4">
                            <h4 class="mb-0">ULTRA FEMME 360™</h4>
                        </div>
                        <div class="card-text"
                             data-aos="fade"
                             data-aos-duration="1000"
                             data-aos-delay="500">
                            <div class="row m-0">
                                <div class="col-4 header text-center px-0 my-3">
                                    風險
                                </div>
                                <div class="col-8 my-3">
                                    一次即棄探頭 非入侵性，零創傷
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="col-4 header text-center px-0 my-3">
                                    感覺
                                </div>
                                <div class="col-8 my-3">
                                    微溫
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="col-4 header text-center px-0 my-3">
                                    需時
                                </div>
                                <div class="col-8 my-3">
                                    8分鐘
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="col-4 header text-center px-0 my-3">
                                    康復
                                </div>
                                <div class="col-8 my-3">
                                    無需恢復期 可即時活動 即晚性行為
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="col-4 header text-center px-0 my-3">
                                    安全
                                </div>
                                <div class="col-8 my-3">
                                    100%安全 美國FDA 歐盟CE認證
                                </div>
                            </div>
                            <div class="row m-0 border-bottom-0">
                                <div class="col-4 header text-center px-0 my-3">
                                    覆蓋
                                </div>
                                <div class="col-8 my-3">
                                    360°全內陰 零盲點
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg card mr-md-4 mb-4 mb-lg-0 p-0"
                     data-aos="fade-up"
                     data-aos-duration="1000">
                    <div class="card-body">
                        <div class="card-title text-center d-flex flex-column justify-content-center px-3 py-4">
                            <h4 class="mb-0">收陰手術/尿道收窄手術</h4>
                        </div>
                        <div class="card-text"
                             data-aos="fade"
                             data-aos-duration="1000"
                             data-aos-delay="500">
                            <div class="row m-0">
                                <div class="col-4 header text-center px-0 my-3">
                                    風險
                                </div>
                                <div class="col-8 my-3">
                                    需全身麻醉
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="col-4 header text-center px-0 my-3">
                                    感覺
                                </div>
                                <div class="col-8 my-3">
                                    痛楚
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="col-4 header text-center px-0 my-3">
                                    需時
                                </div>
                                <div class="col-8 my-3">
                                    2小時
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="col-4 header text-center px-0 my-3">
                                    康復
                                </div>
                                <div class="col-8 my-3">
                                    最少4星期
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="col-4 header text-center px-0 my-3">
                                    安全
                                </div>
                                <div class="col-8 my-3">
                                    留有疤痕 易感染婦科病
                                </div>
                            </div>
                            <div class="row m-0 border-bottom-0">
                                <div class="col-4 header text-center px-0 my-3">
                                    覆蓋
                                </div>
                                <div class="col-8 my-3">
                                    只能修窄陰道 不能增加膠原 加強彈性
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg card mr-md-4 mb-4 mb-lg-0 p-0"
                     data-aos="fade-up"
                     data-aos-duration="1000">
                    <div class="card-body">
                        <div class="card-title text-center d-flex flex-column justify-content-center px-3 py-4">
                            <h4 class="mb-0">舊式Laser CO2激光收陰</h4>
                        </div>
                        <div class="card-text"
                             data-aos="fade"
                             data-aos-duration="1000"
                             data-aos-delay="500">
                            <div class="row m-0">
                                <div class="col-4 header text-center px-0 my-3">
                                    風險
                                </div>
                                <div class="col-8 my-3">
                                    探頭重複使用 易感染婦科病
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="col-4 header text-center px-0 my-3">
                                    感覺
                                </div>
                                <div class="col-8 my-3">
                                    痛楚
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="col-4 header text-center px-0 my-3">
                                    需時
                                </div>
                                <div class="col-8 my-3">
                                    30分鐘
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="col-4 header text-center px-0 my-3">
                                    康復
                                </div>
                                <div class="col-8 my-3">
                                    最少2星期
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="col-4 header text-center px-0 my-3">
                                    安全
                                </div>
                                <div class="col-8 my-3">
                                    消毒問題
                                </div>
                            </div>
                            <div class="row m-0 border-bottom-0">
                                <div class="col-4 header text-center px-0 my-3">
                                    覆蓋
                                </div>
                                <div class="col-8 my-3">
                                    用固定器 有1/3盲點
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p class="text-center mb-0">
                資料及數據由 BTL HONG KONG LIMITED 提供
            </p>
        </div>
    </section>
</template>

<script>
    export default {
        name: "UltraFemme360Comparison"
    }
</script>

<style lang="scss" scoped>
    @import "./variables.scss";

    .comparison {
        background-color: $background-color;

        & > .container {
            & > h2 {
                color: $text-color;
            }

            .cards {
                .card {
                    background-color: rgba(255, 255, 255, 1);

                    .card-body {
                        .card-title {
                            color: rgba(255, 255, 255, 1);
                            background-color: rgba(177, 177, 177, 1);

                            &.featured {
                                color: rgba(255, 255, 255, 1);
                                background-color: $text-color;
                            }
                        }

                        .card-text {
                            .row {
                                border-bottom: solid 1px rgba(201, 201, 201, 1);

                                .header {
                                    color: $text-color;
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
