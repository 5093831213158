<template>
    <section class="feature-1">
        <div class="container py-5">
            <div class="row">
                <div class="col-md-4">
                    <h1 class="text-center font-weight-bold mb-3"
                        data-aos="fade"
                        data-aos-duration="1000">
                        內陰收緊
                    </h1>
                    <div class="img-wrapper d-flex justify-content-center align-items-center mb-5 p-2"
                         data-aos="fade-up"
                         data-aos-duration="1000">
                        <img class="img-fluid" src="../../../assets/campaign/ultra-femme-360-new/feature2.png">
                    </div>

                    <h5 class="text-center"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        膠原自生，增厚陰道壁
                    </h5>
                    <h5 class="text-center"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        提升敏感度
                    </h5>
                    <h5 class="text-center"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        增強彈性
                    </h5>
                    <h5 class="text-center"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        緊緻鬆弛陰道
                    </h5>
                </div>

                <div class="col-md-4">
                    <h1 class="text-center font-weight-bold mb-3"
                        data-aos="fade"
                        data-aos-duration="1000">
                        外陰修型
                    </h1>
                    <div class="img-wrapper d-flex justify-content-center align-items-center mb-5 p-2"
                         data-aos="fade-up"
                         data-aos-duration="1000">
                        <img class="img-fluid" src="../../../assets/campaign/ultra-femme-360-new/feature1.png">
                    </div>

                    <h5 class="text-center"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        膠原自生，緊緻陰唇輪廓
                    </h5>
                    <h5 class="text-center"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        平滑外陰肌膚
                    </h5>
                    <h5 class="text-center"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        美白淡色
                    </h5>
                    <h5 class="text-center"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        收緊陰道口
                    </h5>
                </div>

                <div class="col-md-4">
                    <h1 class="text-center font-weight-bold mb-3"
                        data-aos="fade"
                        data-aos-duration="1000">
                        尿道收緊
                    </h1>
                    <div class="img-wrapper d-flex justify-content-center align-items-center mb-5 p-2"
                         data-aos="fade-up"
                         data-aos-duration="1000">
                        <img class="img-fluid" src="../../../assets/campaign/ultra-femme-360-new/feature3.png">
                    </div>

                    <h5 class="text-center"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        收緊尿道
                    </h5>
                    <h5 class="text-center"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        增強周邊肌肉彈性
                    </h5>
                    <h5 class="text-center"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        增強忍尿控制力
                    </h5>
                    <h5 class="text-center"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        改善尿滲、失禁
                    </h5>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "UltraFemme360Feature1"
    }
</script>

<style lang="scss" scoped>
    @import "./variables.scss";

    .feature-1 {
        //background-color: $background-color-alt;
        background-color: $background-color;
        color: $text-color;

        .container {
            .img-wrapper {
                & > .img-fluid {
                    width: 80%;
                    max-width: 250px;
                }
            }

        }
    }
</style>
