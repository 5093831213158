<template>
    <section class="video">
        <div class="container py-5">
            <div class="video-wrapper d-flex justify-content-center"
                 data-aos="fade"
                 data-aos-duration="1000"
                 data-aos-delay="1500">
                <b-embed type="iframe"
                         aspect="16by9"
                         src="https://www.youtube.com/embed/uflfqEtvFzg"
                         allowfullscreen>
                </b-embed>
            </div>
        </div>
    </section>
</template>

<script>
    import {
        BEmbed,
    } from "bootstrap-vue";

    export default {
        name: "UltraFemme360Video",
        components: {
            BEmbed,
        },
    }
</script>

<style lang="scss" scoped>
    @import "./variables.scss";

    .video {
        background-color: $background-color-alt;
    }
</style>
