<template>
    <article class="ultra-femma-360-v2 overflow-hidden">
        <ultra-femme360-home></ultra-femme360-home>
        <!-- <ultra-femme360-video></ultra-femme360-video> -->
        <ultra-femme360-causes></ultra-femme360-causes>
        <ultra-femme360-feature1></ultra-femme360-feature1>
        <ultra-femme360-feature2></ultra-femme360-feature2>
        <ultra-femme360-comparison></ultra-femme360-comparison>
        <enquiry duplicate-to-g-sheet
                 v-bind:g-sheet-id="gSheetIds"
                 g-sheet-id-key="branch"
                 v-bind:treatment-id="treatmentId">
            免費專業美容顧問咨詢 及 安排首次半價體驗
        </enquiry>
    </article>
</template>

<script>
    import UltraFemme360Home from "./UltraFemme360/Home";
    import UltraFemme360Video from "./UltraFemme360/Video";
    import UltraFemme360Causes from "./UltraFemme360/Causes";
    import UltraFemme360Feature1 from "./UltraFemme360/Feature1";
    import UltraFemme360Feature2 from "./UltraFemme360/Feature2";
    import UltraFemme360Comparison from "./UltraFemme360/Comparison";
    import Enquiry from "./Enquiry";
    export default {
        name: "UltraFemme360V2",
        components: {
            Enquiry,
            UltraFemme360Feature2,
            UltraFemme360Feature1,
            UltraFemme360Causes,
            UltraFemme360Comparison,
            UltraFemme360Video,
            UltraFemme360Home
        },
        metaInfo () {
            return {
                title: this.$sanitize(this.treatment.title[this.$i18n.locale]),
                meta: [{
                    "name": "description",
                    "content": this.$sanitize(this.treatment.subtitle[this.$i18n.locale]),
                    "vmid": "description",
                }, {
                    "property": "og:title",
                    "content": this.$sanitize(this.treatment.title[this.$i18n.locale]),
                    "template": "%s | marier Skincare 曼肌兒",
                    "vmid": "og:title"
                }, {
                    "property": "og:description",
                    "content": this.$sanitize(this.treatment.subtitle[this.$i18n.locale]),
                    "vmid": "og:description"
                }, {
                    "property": "og:type",
                    "content": "profile",
                    "vmid": "og:type"
                }, {
                    "property": "fb:app_id",
                    "content": "148635335285458",
                    "vmid": "fb:app_id"
                }, /*{
                    "property": "og:image",
                    "content": `${ this.$config.apiUrl }/${ this.$config.apiVersion }/shop/product/image/${ this.product.images[0] }`,
                    "vmid": "og:image"
                }*/]
            }
        },
        props: {
            treatmentId: {
                type: String,
                required: true,
            }
        },
        data () {
           return {
               gSheetIds: {
                   "香港銅鑼灣羅素街38號金朝陽中心9樓902-903室": "AKfycbx5tef8q9huChgYmK5bLO65mCL_HEfbBQMzN8HC94LVOTXxcAfe", // CWB
                   "尖沙咀彌敦道132號美麗華廣場A座6樓602室": "AKfycby93s7mJnKmzHMVXiFV61PW7IbdNsPZeBjdRypSQE8cybctymo8" // TST
               },
           }
        },
        computed: {
            treatment () {
                let target = this.$store.state.mall.salon.treatments.find(
                    treatment => treatment._id === this.treatmentId
                );
                if (target) {
                    return target;
                }
                return {
                    title: {
                        "en": "",
                        "zh-hk": "",
                        "zh-cn": ""
                    },
                    subtitle: {
                        "en": "",
                        "zh-hk": "",
                        "zh-cn": ""
                    },
                    availableBranches: [],
                    images: [],
                    cover: "",
                };
            },
        },
        methods: {

        },
    }
</script>

<style lang="scss" scoped>

</style>
